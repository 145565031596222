
.svgContainer{
  max-width: 100%;
  max-height: 80%;

  .svgAppBuilders{
    max-width: 100%;
    max-height: 100%;

    #girl{
      display: none;
    }
    // #apps path{
    //   animation: shake 6s ease-in-out infinite;
    // }
    // #apps path:nth-child(2n){
    //   animation: shake 6s ease-in-out infinite 1s;
    // }
    #boy #appHand{
      animation: move 6s ease-in-out infinite;
      transform-origin: bottom right;
      transform-box: fill-box;
    }

    #floor{
      display: none;
    }

    .cls-3{
      fill: #4eb1ca ;
    }

    &.visible{
      #apps, #appPlaceholders{
        animation: appear 6s ease-in-out;
      }
    }
  }
}


@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1
	}
}

@keyframes move {
	0%, 100% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(7deg);
	}
}


@keyframes float {
	0% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(5px);
	}
	50% {
		// box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-5px);
	}
	100% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(5px);
	}
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}