.navigation{
  z-index: 20;
}

.NavBorder{
  background-color: #fff;
  position: fixed;
  color: #000;
  font-size: 1.5rem;

   &.vertical{
    width: 50px;
    height: 100vh;
  }

  &.horizontal{
    width: 100vw;
    height: 50px;
  }

  &.NavBorderTop{
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    

    & div{
      color: #1F445F;
        &:hover{
        color: #4499B3;
      }
    }
  }

  &.NavBorderBottom{
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;

    & .socialIcon{
      cursor: pointer;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      a{
        color: #1F445F;
        &:hover{
          color: #4499B3;
        }
      }


      &:nth-last-of-type(1){
        margin-right: 50px;
      }
    }
  }

  &.NavBorderLeft{
    left: 0;
    bottom: 0;
  }

  &.NavBorderRight{
    right: 0;
    top: 0;
  }

  .nav{
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 50px;

    & > div{
      margin-left: 30px;
      cursor: pointer;
    }
  }

  .logo{
    margin-left: 50px;
    cursor: pointer;
  }
}

.smallNavMenuContainer{
  position: absolute;
  top: 0px;
  left: -60vw;
  height: 100vh;
  width: 50vw;
  transition: left 0.5s ease-in-out;
  z-index: inherit;

  .background{
    position: fixed;
    left: 0%;
    top: 0%;
    display: inline-block;
    border-bottom: 100vh solid transparent;
    border-left: 0vw solid #fff;
    transition: all 0.5s ease-in-out;
  }

  .dismissMenu{
    background-color: transparent;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 0vw;
    transition: all 0.5s ease-in-out;
  }

  .menu{
    font-size: 2rem;
    font-weight: bold;
    position: fixed;
    left: -100%;
    top: 20px;
    z-index: 10;
    transition: all 0.5s ease-in-out;
    color: #1F445F;

    .menuLogo{
      font-size: 1rem;
    }

    & div{
      padding: 10px;
      font-size: 2rem;
      margin-bottom: 20px;
      transition: all ease-in-out 0.3s;

      &:hover{
        color: #4499B3;
      }
    }
  }

  &.diam{
    left: 0vw;

    .background{
      border-left-width: 80vw;
    }

    .dismissMenu{
      width: 100vw;
    }

    .menu{
      left: 20px;
    }
  }
}

.smallNav{
  position: fixed;
  top: 0px;
  left: 5vw;
  width: 90vw;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo{
    position: relative;
    z-index: 20;
    transition: color 0.5s ease-in-out;
    color: #fff;
  }

  &.diam .logo{
    color: transparent;
  }

  #pencet{
    display:flex;
    align-items:center;
    flex-direction:column;
    cursor:pointer;
  }
  
  #pencet span{
    background-color:ghostwhite;
    width:2em;
    height:.2em;
    margin:0.26em 0;
    display:inline;
    transition: all .4s ease;
    transform-origin:0 0;
  }
  
  .diam span:nth-child(1) {
    transform: rotate(45deg) translate(1px, -1px);
  }
  
  .diam span:nth-child(2) {
    Transform: scaleX(0);
  }
  
  .diam span:nth-child(3) {
    transform: rotate(-45deg) translate(1px, 0);
  }
}

@media screen and (max-width: 800px) {
  .NavBorder{
    display: none !important;
  }
}

@media screen and (min-width: 800px) {
  .smallNav{
    display: none !important;
  }
}