section.one{
  height: 100%;
  & > .sectionOneMotion{
    width: 100%;
    height: 100%;
    position: relative;

    .svgAppBuilders{
      position: absolute;
      bottom: 10vh;
      right: 0px;
      height: 50%;
      z-index: 1;
      opacity: 1;

      @media screen and (min-width: 800px){
        bottom: 15vh;
        right: 200px;
        height: 35%;
      }

      @media screen and (min-width: 1000px){
        bottom: 15vh;
        right: 100px;
        height: 45%;
      }

      @media screen and (min-width: 1300px){
        bottom: 15vh;
        right: 0px;
        height: 80%;
      }
    }

    .title{
      text-align: center;
      margin-top: 100px;
      letter-spacing: 7px;

      @media screen and (min-width: 800px){
        margin: 100px;
      }
  
      h1{
        text-align: left;
        font-size: 2rem;
        color: #fff;
        // font-family: 'Playfair Display', serif;
        
        &.name{
          font-family: 'Permanent Marker', cursive;
        }


        @media screen and (min-width: 800px){
          font-size: 4rem;
        }
      }
  
      .textLoopItem{
        color: #5dcae6;
        // font-family: monospace;
        // font-family: 'Playfair Display', serif;
      }
      p.textLoopItem{
        margin-bottom: 24px;
      }
    }

  }

}

section.two{
  height: 125%;

  @media screen and (min-width: 800px){
    height: 100%;
  }
  @media screen and (min-height: 700px){
    height: 110%;
  }
  @media screen and (min-height: 800px){
    height: 100%;
  }

  & > .sectionTwoMotion{
    width: 100%;
    height: 100%;
    position: relative;

    .personalizationSvg{
      position: absolute;
      bottom: 15vh;
      left: 0px;
      width: 100%;
      height: 40%;
      z-index: -1;
      opacity: 1;

      @media screen and (min-height: 800px){
        bottom: 10vh;
      }

      @media screen and (min-width: 800px){
        bottom: 15vh;
        left: 200px;
        width: 50%;
        height: 40%;
      }

      @media screen and (min-width: 1000px){
        bottom: 15vh;
        left: 100px;
        width: 50%;
        height: 40%;
      }

      @media screen and (min-width: 1300px){
        bottom: 20vh;
        left: 50px;
        width: 40%;
        height: 60%;
      }
    }

    .whoAmI{
      margin-top: -20px;
      
      @media screen and (min-width: 800px){
        width: 80%;
        position: absolute;
        top: 20px;
        right: 10%;
      }

      @media screen and (min-width: 1000px){
        width: 70%;
        position: absolute;
        top: 20px;
        right: 20px;
      }

      @media screen and (min-width: 1300px){
        width: 50%;
        position: absolute;
        top: 20%;
        right: 20px;
      }

      h1{
        color: #5DCBE6;
        margin-bottom: 20px;
        font-size: 2rem;
      }

      p{
        color: #fff;
        margin-bottom: 20px;
        font-size: 1rem;
        @media screen and (min-width: 800px){
          font-size: 1.5rem;
        }
      }
    }

  }

}

section.three{
  height: 100%;
  & > .sectionThreeMotion{
    width: 100%;
    height: 100%;
    position: relative;

    @media screen and (max-width: 800px) and (min-height: 700px){
      height: 90%;
    }

    .feelingProudSvg{
      position: absolute;
      bottom: 0vh;
      right: 0px;
      width: 50%;
      height: 40%;
      z-index: 1;
      opacity: 1;

      @media screen and (max-width: 800px) and (min-height: 700px){
        bottom: -5vh;
      }

      @media screen and (min-width: 800px){
        bottom: 5vh;
        right: 100px;
        width: 25%;
        height: 40%;
      }

      @media screen and (min-width: 1000px){
        bottom: 15vh;
        left: 50px;
        width: 35%;
        height: 40%;
      }

      @media screen and (min-width: 1300px){
        bottom: 15vh;
        left: 100px;
        width: 45%;
        height: 60%;
      }
    }

    .skills{
      width: 100%;
      margin-top: -20px;
      right: 0px;
      
      @media screen and (min-width: 800px){
        width: 80%;
        position: absolute;
        top: 10px;
        right: 5%;
      }

      @media screen and (min-width: 1000px){
        width: 50%;
        position: absolute;
        top: 20px;
        right: 0px;
      }

      @media screen and (min-width: 1300px){
        width: 50%;
        position: absolute;
        top: 15%;
        right: 0px;
      }

      h1{
        color: #5DCBE6;
        margin-bottom: 20px;
        font-size: 3rem;
      }

      .brief{
        list-style: none;
        margin-left: -40px;
        margin-bottom: 30px;
        li{
          color: white;
          letter-spacing: 1px;
          font-size: 1.3rem;
          margin-bottom: 10px;
        }
      }

      p{
        color: #fff;
        margin-bottom: 10px;
        font-size: 1rem;
        @media screen and (min-width: 800px){
          font-size: 1.5rem;
        }
      }
    }

  }

}

section.four{
  height: 100%;
  .sectionFourMotion{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .workContent{
      height: 80%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 1000px){
        flex-direction: row;
      }
    }

    .mostRecent, .other{
      height: 100%;
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h1{
      font-size: 2rem;
      color: #5DCBE6;

      @media screen and (min-width: 800px){
        font-size: 3rem;
        margin-bottom: 0px;
      }
    }

    .mostRecent{
      flex-direction: column;
      // h3{
      //   font-size: 1.3rem;
      //   color: #fff;
      //   margin: 20px;
      // }
      .mostRecentItem{
        background-color: #5DCBE6;
        border-radius: 30px;
        min-height: 200px;
        flex-grow: 0;
        max-height: 60%;
        width: 95%;

        @media screen and (min-width: 800px){
          width: 85%;
          flex-grow: 1;
        }
      }
    }

    .other{
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      @media screen and (min-width: 1000px){
        max-height: 65%;
      }

      .otherWorkItem{
        background-color: #5DCBE6;
        border-radius: 30px;
        height: 25%;
        width: 40%;
      }
    }
  }
}

section.four2{
  min-height: 100%;
  .sectionFourMotion{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .experienceContent{
      height: 80%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    h1{
      font-size: 2rem;
      color: #5DCBE6;
      margin-bottom: 30px;

      @media screen and (min-width: 800px){
        font-size: 3rem;
      }
    }

    .experienceContent{
      .experienceItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        width: 100%;

        @media screen and (min-width: 800px ){
          width: 80%;

        }

        @media screen and (min-width: 1000px ){
          width: 90%;
          flex-direction: row;
          margin-bottom: 50px;
          text-align: left;
        }


        .experienceInfo{
          @media screen and (max-width: 1000px ){
            width: 100%;
            margin-bottom: 20px;
            margin-top: 40px;
          }
          @media screen and (min-width: 1000px ){
            width: 30%;
          }
          @media screen and (min-width: 1300px ){
            margin-left: 50px;
          }
          .role{
            font-size: 1.7rem;
            color: white;
            font-family: monospace;
          }

          .company{
            font-size: 1.4rem;
            color: #5dcae6;
            font-family: monospace;
          }

          .place, .year{
            font-size: 1rem;
            color: #adadad;
            font-family: monospace;
          }
        }
        
        .tasks{
          @media screen and (max-width: 1000px ){
            width: 100%;
          }

          @media screen and (min-width: 1000px ){
            width: 60%;
            flex-direction: row;
            font-size: 1.3rem;
            margin-left: 30px;
          }
          ul{
            padding-left: 25px;
            li{
              font-family: monospace;
              color: white;
            }
          }
        }

      }
    }


  }
}

section.five{
  height: 100%;
  .sectionFiveMotion{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    h1{
      text-align: center;
      font-size: 3rem;
      color: #fff;
      margin-bottom: 50px;
    }

    h3{
      text-align: center;
      font-size: 2rem;
      color: #fff;
      margin-bottom: 20px;
    }

    .mailBtn{
      padding: 20px;
      color: #fff;
      background-color: #5DCBE6;
      border-radius: 25px;
      font-size: 2.5rem;
      margin-bottom: 30px;
    }
    
    a{
      text-decoration: none;
    }

    .socialIcons{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .socialIcon{
        a{
          font-size: 3rem;
          margin: 5px;
          color: #fff;
          background-color: #5DCBE6;
          border-radius: 25px;
          height: 5rem;
          width: 5rem;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (min-width: 800px){
            margin: 30px;
          }
        }
      }
    }
  }
}