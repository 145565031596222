body, html{
  width: 100vw;
  font-family: 'Oswald', sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}

.App{
  background-color: #1F445F;
  overflow-x: hidden;
  outline: hotpink 2px solid;
}