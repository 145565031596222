.sectionComp{
  position: relative;
  height: calc(100vh - 100px);
  width: calc(100vw - 100px);
  margin: auto;
  margin-top: 20px;

  &:nth-of-type(4){
    height: 100vh;
  }

  &:nth-of-type(3){
    height: 105vh;
  }

  &:nth-of-type(3){
    min-height: 105vh;
    height: auto;
    padding-bottom: 30px;
  }

  @media screen and (min-width: 800px) {
    height: calc(100vh - 100px);
    margin-top: 50px;
  }

}

