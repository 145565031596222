*{
  margin: 0;
  font-family: 'Champagne & Limousines';
  // font-family: monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

@import './app.scss';
@import './appBuildersSvg.scss';
@import './feelingProudSvg.scss';
@import './Nav.scss';
@import './SectionComp.scss';
@import './Content.scss';



