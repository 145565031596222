.svgContainer{
  max-width: 100%;
  max-height: 80%;

  .feelingProudSvg{
    max-width: 100%;
    max-height: 100%;

    .cls-3{
      fill: #4eb1ca ;
    }
  }
}